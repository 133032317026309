html, body, #root, #app-container {
  height: 100%;
}

#app-container {
  overflow: auto;
  background-color: #11203e;
}

#navbar {
  height: 86px;
}

#land-view, #manager-view, #admin-view {
  height: calc(100% - 86px);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h1, h2, h3, h4, h5 {
  font-family: Roboto, sans-serif;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.clickable {
  cursor: pointer;
  transition: .25s;
}

.clickable.rounded {
  border-radius: 3px;
}

.clickable:hover {
  background-color: #ddd !important;
  color: black !important;
}

rect.clickable:hover {
  fill: #ddd !important;
}

.clickable-dark:hover .Dropdown-placeholder{
  background-color: #aaa !important;
  color: white !important;
  cursor: pointer;
}

.clickable-dark:hover {
  background-color: #aaa !important;
  color: white !important;
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

.navbar-logo{
    height: 60px;
    margin-right: 40px;
}

.mobile-display .navbar-logo{
    margin-right: 20px;
}

#navbar-title {
  color: white;
  display: inline-block;
  font-size: 60px;
  line-height: 60px;
  vertical-align: top;
  font-family: Arial, sans-serif;
  font-weight: bold;
  margin-right: 30px;
}

.mobile-display #navbar-title {
    font-size: 36px;
    margin-right: 0;
}

.navbar-menu-option {
  color: white;
  display: inline-block;
  vertical-align: top;
  font-family: Roboto, sans-serif;
  line-height: 60px;
  margin: 0 15px;
  padding: 0 20px;
  font-size: 40px;
  cursor: pointer;
}

@media (max-width: 1500px) {

  .navbar-menu-option {
    font-size: 30px;
  }
}

.mobile-display .modal {
    width: 90%;
    max-height: 90%;
}

.mobile-display .modal .modal-content {
    padding: 12px;
}

.mobile-display .auto-complete {
    width: 280px;
}

.loadingLayerWrapper {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}

.loadingBox {
  background-color: white;
  padding: 2em;
}

.tool-tip {
  z-index: 10001 !important;
}

.reglisse {
  font-family: Roboto, sans-serif;
}

.user-navbar-display {
  font-family: Roboto, sans-serif;
  position: absolute;
  top: 0;
  right: 20px;
}

.center-align{
  text-align: center;
}

.inline-block{
  display: inline-block;
}

.lean-overlay.alert-popup-overlay {
  z-index: 4500 !important;
}

.utils-btn {
  font-size: 48px!important;
  line-height: 32px!important;
  padding: 0 10px!important;
}

.lm {
  margin-left: 10px;
}

.lm-30 {
  margin-left: 30px;
}

.rm {
  margin-right: 10px;
}

.rm-30 {
  margin-right: 30px;
}

.tm {
  margin-top: 10px;
}

.tm-5 {
    margin-top: 5px;
}

.bm {
  margin-bottom: 10px;
}



.transition-height{
  transition: max-height .5s linear;
}

.view-select-btn {
  font-family: Roboto, sans-serif;
  font-size: 28px!important;
  border-radius: 5px!important;
  background-color: grey!important;
  line-height: 40px!important;
  height: 40px!important;
}


.ag-input-field-input.ag-text-field-input{
    height: 35px!important;
    margin-top: 5px!important;
}

.ag-floating-filter-button, .ag-header-cell-menu-button{
    display: none!important;
}

.theme-btn {
  background-color: #752eb6!important;
  border-radius: 18px!important;
}

.table-border tr, .table-border td, .table-border th {
  border: 1px solid rgb(210, 210, 210);
  border-collapse: collapse;
}

.mobile-display .card .card-content {
    padding: 10px;
}

.mobile-display .order-card .material-icons {
    font-size: 30px;
}

.modal{
    top:5%!important;
    max-height: 90%!important;
}
