.dropdownPanel {
    display: inline-block;
    z-index: 1004;
    max-width: calc(100% - 5em);
    &.opened {
        div.clickable {
            color: black !important;
            background-color: #ddd !important;
        }
    }
    &:hover {
        max-width: fit-content;
        background-color: white;
    }
}

.dropdownHeader {
    position: relative;
    border-radius: 3px;
    border: 1px solid #9e9e9e;
    cursor: pointer;
    .dropdownValue {
        padding: 5px;
        margin-right: 10px;
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;
    }
}

.dropdownCaret {
    position: absolute;
    right: 0;
    padding: 10px 5px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    font-weight: bold;
}

.dropdownPanelContent {
    position: absolute;
    background-color: white;
    overflow-x: hidden;
    padding: .5em;
    z-index: 1004;
}

.fullWidth {
    left: 10%;
    width: 80%;
}
